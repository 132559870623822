import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ marginTop: `100px`, textAlign: `center`, height: `300px` }}>
      <h1>Sivua ei löytynyt</h1>
      <p>Oops, täältä ei löytynytkään mitään....</p>
    </div>
  </Layout>
);

export default NotFoundPage;
